import React from "react";
import BlogContent from "../components/BlogContent";
import Img from "../images/tutorials/fifteen.png";

const TutorialFifteen = () => {
    return (
        <div>
            <BlogContent
                title="Simple Method to Create a Scroll Progress Bar with React/NextJS and Framer Motion"
                date="Written by Thomas, Published on 24/01/2024"
                image={Img}
                alt="tutorial"
                video="https://youtu.be/MUpVNTGVIPE"
                details="
                In this step-by-step tutorial, you'll learn how to effortlessly create a scroll progress bar for your React/NextJS project using Framer Motion. This versatile component, once implemented, can be seamlessly integrated into any page of your project and customised with the help of CSS styling. 🚀"
                conclusion="I'm documenting my coding & design journey, from learning programming concepts to building and designing projects, on my YouTube channel. To follow along and stay updated, be sure to subscribe to my channel."
                github="https://github.com/emersonvisuals/progress-bar-scroll-tutorial"
                other="/"
                otherTitle=""
                otherButton="none"
            />
        </div>
    );
};

export default TutorialFifteen;
