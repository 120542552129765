import React from "react";
import BlogContent from "../components/BlogContent";
import Img from "../images/tutorials/sixteen.png";

const TutorialSixteen = () => {
    return (
        <div>
            <BlogContent
                title="MUST HAVE Resources for Website Design Inspirations"
                date="Written by Thomas, Published on 11/02/2024"
                image={Img}
                alt="tutorial"
                video="https://youtu.be/R7acN23tvys"
                details="
                Dive into the must-have resources to take your web design skills to the next level! Whether you're a seasoned designer or just starting out, these tools will spark your creativity and guide you towards creating award-winning websites. 
                 To find out what these resources are, check out the video below 🚀"
                conclusion="I'm documenting my coding & design journey, from learning programming concepts to building and designing projects, on my YouTube channel. To follow along and stay updated, be sure to subscribe to my channel."
                github="/"
                githubButton="none"
                other="/"
                otherTitle=""
                otherButton="none"
            />
        </div>
    );
};

export default TutorialSixteen;
