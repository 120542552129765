import React from "react";

const Card = ({
    link,
    img,
    title,
    details,
    colorOne,
    skillOne,
    colorTwo,
    skillTwo,
    colorThree,
    skillThree,
    colorFour,
    skillFour,
    amount,
}) => {
    return (
        <a href={link}>
            <div className="cardContainer" style={{ margin: amount }}>
                <img src={img} alt="one" />
                <div className="contents">
                    <h1 className="header">{title}</h1>
                    <div className="skillsContainer">
                        <p className="skills">
                            <span className={colorOne}>{skillOne}</span>
                            <span className={colorTwo}>{skillTwo}</span>
                            <span className={colorThree}>{skillThree}</span>
                            <span className={colorFour}>{skillFour}</span>
                        </p>
                    </div>
                    <p className="paragraph">{details}</p>
                </div>
            </div>
        </a>
    );
};

export default Card;
