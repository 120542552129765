import React from "react";
import Navbar from "./Navbar";
import Main from "./Main";
import Section from "./Section";
import Footer from "./Footer";

const Home = ({
    firstRef,
    firstRefVisible,
    secondRef,
    secondRefVisible,
    thirdRef,
    thirdRefVisible,
    titleOne,
    titleTwo,
}) => {
    return (
        <div>
            <Navbar />
            <Main
                titleOne="Online"
                titleTwo="Tutorials"
                firstRef={firstRef}
                firstRefVisible={firstRefVisible}
            />
            <Section
                secondRef={secondRef}
                secondRefVisible={secondRefVisible}
            />
            <Footer />
        </div>
    );
};

export default Home;
