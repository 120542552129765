import React from "react";
import "../styles/main.scss";
import FooterLogo from "../images/footerLogo.svg";

const Footer = () => {
    return (
        <footer>
            <div className="contents">
                <div class="logo">
                    <img src={FooterLogo} alt="footer logo" />
                </div>
                <div class="footerList">
                    <div class="footerPortfolio">
                        <h1>portfolio</h1>
                        <ul>
                            <li>
                                <a
                                    href="https://www.emersonvisuals.com/uxuidesign/"
                                    class="footerUXUI"
                                >
                                    UX/UI Design
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.emersonvisuals.com/graphicdesign/"
                                    class="footerGraphicDesign"
                                >
                                    Graphic Design
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.emersonvisuals.com/architecture/"
                                    class="footerArchitecture"
                                >
                                    Architecture
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.emersonvisuals.com/digitalartwork/"
                                    class="footerDigitalArtwork"
                                >
                                    Digital Artwork
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.emersonvisuals.com/frontenddevelopment/"
                                    class="footerFrontendDevelopment"
                                >
                                    Frontend Development
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="footerSocials">
                        <h1>socials</h1>
                        <ul>
                            <li>
                                <a
                                    href="https://www.facebook.com/emersonvisuals"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="footerFacebook"
                                >
                                    Facebook
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/emersonvisuals/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="footerInstagram"
                                >
                                    Instagram
                                </a>
                            </li>
                            {/* <li>
                                <a
                                    href="https://www.tiktok.com/@emersonvisuals"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="footerTiktok"
                                >
                                    Tiktok
                                </a>
                            </li> */}
                            <li>
                                <a
                                    href="https://www.youtube.com/c/ThomasEmerson"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="footerYoutube"
                                >
                                    Youtube
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://github.com/emersonvisuals"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="footerGithub"
                                >
                                    Github
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="footerContact">
                        <h1>contact</h1>
                        <ul>
                            <li>
                                <a
                                    href="mailto:hello@emersonvisuals.com"
                                    class="footerContact"
                                >
                                    Contact
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.linkedin.com/in/thomas-emerson-93262b17a/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="footerLinkedin"
                                >
                                    Linkedin
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="copyrightContainer">
                <p className="copyright">
                    Emerson Visuals © 2023 All Rights Reserved.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
