import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
// import AdOne from "./AdOne";
import AdTwo from "./AdTwo";
import BreadCrumbs from "./BreadCrumbs";
import "../styles/main.scss";

const BlogContent = ({
    title,
    date,
    image,
    details,
    video,
    github,
    githubButton,
    alt,
    conclusion,
    other,
    otherTitle,
    otherButton,
}) => {
    return (
        <div>
            <Navbar />
            <section className="blogContent">
                <div className="contents">
                    <BreadCrumbs />
                    <h1 className="title">{title}</h1>
                    <p className="date">{date}</p>
                    <img src={image} alt={alt} />
                    <p>{details}</p>
                    {/* <div className="adContainer">
                        <span className="advertisement">advertisement</span>
                        <AdOne />
                    </div> */}
                    <p style={{ padding: "0" }}>{conclusion}</p>
                    <div className="adContainer">
                        <span className="advertisement">advertisement</span>
                        <AdTwo />
                    </div>
                    <div className="buttons">
                        <a href={video} target="_blank" rel="noreferrer">
                            <button className="btnBlue">video here!</button>
                        </a>
                        <a href={github} target="_blank" rel="noreferrer">
                            <button
                                className="btnWhite"
                                style={{ display: githubButton }}
                            >
                                assets here!
                            </button>
                        </a>
                        <a href={other} target="_blank" rel="noreferrer">
                            <button
                                className="btnBlue"
                                style={{
                                    backgroundColor: "white",
                                    display: otherButton,
                                }}
                            >
                                {otherTitle}
                            </button>
                        </a>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default BlogContent;
