import Tutorials from "./components/Tutorials";
import TutorialOne from "./pages/TutorialOne";
import TutorialTwo from "./pages/TutorialTwo";
import TutorialThree from "./pages/TutorialThree";
import TutorialFour from "./pages/TutorialFour";
import TutorialFive from "./pages/TutorialFive";
import TutorialSix from "./pages/TutorialSix";
import TutorialSeven from "./pages/TutorialSeven";
import TutorialEight from "./pages/TutorialEight";
import TutorialNine from "./pages/TutorialNine";
import TutorialTen from "./pages/TutorialTen";
import TutorialEleven from "./pages/TutorialEleven";
import TutorialTwelve from "./pages/TutorialTwelve";
import TutorialThirteen from "./pages/TutorialThirteen";
import TutorialFourteen from "./pages/TutorialFourteen";
import TutorialFifteen from "./pages/TutorialFifteen";
import TutorialSixteen from "./pages/TutorialSixteen";
import TutorialSeventeen from "./pages/TutorialSeventeen";
import TutorialEighteen from "./pages/TutorialEighteen";
import TutorialNineteen from "./pages/TutoriaNinteen";
import TutorialTwenty from "./pages/TutorialTwenty";
import TutorialTwentyOne from "./pages/TutorialTwentyOne";
import TutorialTwentyTwo from "./pages/TutorialTwentyTwo";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useInView } from "react-intersection-observer";

function App() {
    // intersection observer
    const { ref: firstRef, inView: firstRefVisible } = useInView({
        rootMargin: "-100px",
        threshold: 0,
        triggerOnce: true,
    });
    const { ref: secondRef, inView: secondRefVisible } = useInView({
        rootMargin: "-100px",
        threshold: 0,
        triggerOnce: true,
    });
    const { ref: thirdRef, inView: thirdRefVisible } = useInView({
        rootMargin: "-100px",
        threshold: 0,
        triggerOnce: true,
    });
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route
                        path="/"
                        element={
                            <Tutorials
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialone"
                        element={
                            <TutorialOne
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialtwo"
                        element={
                            <TutorialTwo
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialthree"
                        element={
                            <TutorialThree
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialfour"
                        element={
                            <TutorialFour
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialfive"
                        element={
                            <TutorialFive
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialsix"
                        element={
                            <TutorialSix
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialseven"
                        element={
                            <TutorialSeven
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialeight"
                        element={
                            <TutorialEight
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialnine"
                        element={
                            <TutorialNine
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialten"
                        element={
                            <TutorialTen
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialeleven"
                        element={
                            <TutorialEleven
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialtwelve"
                        element={
                            <TutorialTwelve
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialthirteen"
                        element={
                            <TutorialThirteen
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialfourteen"
                        element={
                            <TutorialFourteen
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialfifteen"
                        element={
                            <TutorialFifteen
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialsixteen"
                        element={
                            <TutorialSixteen
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialseventeen"
                        element={
                            <TutorialSeventeen
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialeighteen"
                        element={
                            <TutorialEighteen
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialnineteen"
                        element={
                            <TutorialNineteen
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialtwenty"
                        element={
                            <TutorialTwenty
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialtwentyone"
                        element={
                            <TutorialTwentyOne
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                    <Route
                        path="/tutorialtwentytwo"
                        element={
                            <TutorialTwentyTwo
                                firstRef={firstRef}
                                firstRefVisible={firstRefVisible}
                                secondRef={secondRef}
                                secondRefVisible={secondRefVisible}
                                thirdRef={thirdRef}
                                thirdRefVisible={thirdRefVisible}
                            />
                        }
                    />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
