import React from "react";
import BlogContent from "../components/BlogContent";
import Img from "../images/tutorials/seven.png";

const TutorialSeven = () => {
    return (
        <div>
            <BlogContent
                title="Responsive Landing Page Using HTML, CSS & JavaScript"
                date="Written by Thomas, Published on the 01/05/2023"
                image={Img}
                alt="tutorial"
                video="https://youtu.be/3XPkz58tbkc"
                details="
                Creating a responsive landing page is essential in today's digital age, as more and more people are accessing websites through a variety of devices with different screen sizes. This tutorial provides a comprehensive guide on how to design and develop such a page using HTML, CSS, and Javascript. The video starts by explaining the importance of responsiveness and the challenges that come with designing for different screen sizes. It then walks through the process of creating a responsive layout, which adapts to different devices seamlessly. The tutorial also covers how to implement interactive elements using Javascript, making the landing page engaging and user-friendly. Additionally, the tutorial provides all the resources necessary for following along, such as sample code and assets, making it easy for learners to follow at their own pace. By the end of the tutorial, viewers will have a solid understanding of responsive web design principles and will be able to apply them to create their own landing pages that work well on all devices."
                conclusion="I'm documenting my coding journey, from learning programming concepts to building and designing projects, on my YouTube channel. To follow along and stay updated, be sure to subscribe to my channel."
                github="https://github.com/emersonvisuals/tutorial-responsive-landing-page"
                otherButton="none"
            />
        </div>
    );
};

export default TutorialSeven;
