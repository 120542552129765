import React from "react";
import "../styles/main.scss";

const Main = ({ titleOne, titleTwo, firstRef, firstRefVisible }) => {
    return (
        <main>
            <header ref={firstRef} class={firstRefVisible ? "active" : ""}>
                <div class="title">
                    <ul>
                        <li>
                            <h1 class="titleOutline">
                                <span>{titleOne}</span> {titleTwo}
                            </h1>
                        </li>
                        <li>
                            <h1 class="titleCenter">
                                <span>{titleOne}</span> {titleTwo}
                            </h1>
                        </li>
                        <li>
                            <h1 class="titleOutline">
                                <span>{titleOne}</span> {titleTwo}
                            </h1>
                        </li>
                    </ul>
                </div>

                <div
                    class="portfolioBelow"
                    id="scroll"
                    data-direction="opacityAndScale"
                    data-rate="0.003"
                >
                    <img src="/images/scroll down.svg" alt="" />
                </div>
            </header>
            <div
                class="background"
                id="scroll"
                data-rate="0.1"
                data-direction="horizontal"
            >
                <svg
                    viewBox="0 0 835.814 509.682"
                    preserveAspectRatio="xMidYMax meet"
                >
                    <g id="Background" opacity="0.25">
                        <path
                            id="Path_2851"
                            data-name="Path 2851"
                            d="M1698.467,163.007V47.282a8.63,8.63,0,0,1,8.654-8.623h233.6l68.885,133H1707.12A8.656,8.656,0,0,1,1698.467,163.007Z"
                            transform="translate(-1698.467 -38.655)"
                            fill="#edeef4"
                        />
                        <path
                            id="Path_2852"
                            data-name="Path 2852"
                            d="M1698.467,44.717h339.578l69.2,133.592H1707.12a8.65,8.65,0,0,1-8.654-8.623V44.717Z"
                            transform="translate(-1698.467 143.19)"
                            fill="#edeef4"
                        />
                        <path
                            id="Path_2853"
                            data-name="Path 2853"
                            d="M1707.12,50.794h428.571L2204.7,184.076H1707.12a8.65,8.65,0,0,1-8.654-8.623V59.417A8.65,8.65,0,0,1,1707.12,50.794Z"
                            transform="translate(-1698.467 325.606)"
                            fill="#edeef4"
                        />
                        <path
                            id="Path_2854"
                            data-name="Path 2854"
                            d="M1967.848,268.141,1850.9,38.659H1708.373l263.987,509.67,264.5-509.573H2086.484Z"
                            transform="translate(-1401.046 -38.659)"
                            fill="#edeef4"
                        />
                    </g>
                </svg>
            </div>
        </main>
    );
};

export default Main;
