// Import necessary dependencies and components...
import React, { useState } from "react";
// import AdOne from "./AdOne";
import AdTwo from "./AdTwo";
import Card from "./Card";
import One from "../images/tutorials/one.png";
import Two from "../images/tutorials/two.png";
import Three from "../images/tutorials/three.png";
import Four from "../images/tutorials/four.png";
import Five from "../images/tutorials/five.png";
import Six from "../images/tutorials/six.png";
import Seven from "../images/tutorials/seven.png";
import Eight from "../images/tutorials/eight.png";
import Nine from "../images/tutorials/nine.png";
import Ten from "../images/tutorials/ten.png";
import Eleven from "../images/tutorials/eleven.png";
import Twelve from "../images/tutorials/twelve.png";
import Thirteen from "../images/tutorials/thirteen.jpg";
import Fourteen from "../images/tutorials/fourteen.png";
import Fifteen from "../images/tutorials/fifteen.png";
import Sixteen from "../images/tutorials/sixteen.png";
import Seventeen from "../images/tutorials/seventeen.png";
import Eighteen from "../images/tutorials/eighteen.png";
import Nineteen from "../images/tutorials/nineteen.png";
import Twenty from "../images/tutorials/twenty.png";
import TwentyOne from "../images/tutorials/twentyone.png";
import TwentyTwo from "../images/tutorials/twentytwo.png";
import "../styles/main.scss";

const Section = ({ secondRef, secondRefVisible }) => {
    const [page, setPage] = useState(0);
    const cardsPerPage = 12;

    const allCards = [
        {
            link: "/tutorialtwentytwo",
            img: TwentyTwo,
            title: "Modern Horizontal Scroll Component in React with Framer Motion",
            details: "Learn how to create an awesome scroll carousel!",
            colorOne: "blue",
            skillOne: "React",
            colorTwo: "red",
            skillTwo: "framer motion",
        },
        {
            link: "/tutorialtwentyone",
            img: TwentyOne,
            title: "Create a Typography Hover Interaction with React and Framer Motion",
            details: "Learn how to create a unique typography effect!",
            colorOne: "blue",
            skillOne: "React",
            colorTwo: "red",
            skillTwo: "framer motion",
        },
        {
            link: "/tutorialtwenty",
            img: Twenty,
            title: "Create a Masked Cursor Hover with React and Framer Motion",
            details: "Learn how to create a masked cursor hover effect!",
            colorOne: "blue",
            skillOne: "React",
            colorTwo: "red",
            skillTwo: "framer motion",
        },
        {
            link: "/tutorialnineteen",
            img: Nineteen,
            title: "Create a Content Carousel with Next.js and Framer Motion",
            details: "Learn how to build a modern carousel component!",
            colorOne: "blue",
            skillOne: "Next.JS",
            colorTwo: "red",
            skillTwo: "framer motion",
        },
        {
            link: "/tutorialeighteen",
            img: Eighteen,
            title: "Recreating STRIPE's Hero Section With Next.js & Spline 3D",
            details:
                "Learn how to recreate STRIPE's famous hero section today!",
            colorOne: "blue",
            skillOne: "Next.JS",
            colorTwo: "green",
            skillTwo: "Spline 3D",
        },
        {
            link: "/tutorialseventeen",
            img: Seventeen,
            title: "On-scroll image animations with Next.Js & Framer Motion",
            details:
                "Learn how to create seamless image animations with Next.JS & Framer Motion",
            colorOne: "blue",
            skillOne: "Next.JS",
            colorTwo: "red",
            skillTwo: "Framer Motion",
        },
        {
            link: "/tutorialsixteen",
            img: Sixteen,
            title: "Helpful resources for Website Design Inspiration",
            details:
                "Discover useful inspiration resources to improve your web design & UI design skill today",
            colorOne: "green",
            skillOne: "Web Design",
            colorTwo: "yellow",
            skillTwo: "UI Design",
        },
        {
            link: "/tutorialfifteen",
            img: Fifteen,
            title: "Scroll Progress Bar with React/NextJS and Framer Motion",
            details:
                "Learn to create a simple scroll progress bar with react/nextjs and framer motion",
            colorOne: "blue",
            skillOne: "NextJS",
            colorTwo: "red",
            skillTwo: "Framer Motion",
        },
        {
            link: "/tutorialfourteen",
            img: Fourteen,
            title: "6 AI Tools with Figma - Rapid Web Design Tutorial",
            details: "Learn to create a web design using 6 different AI tools!",
            colorOne: "green",
            skillOne: "AI tools",
            colorTwo: "blue",
            skillTwo: "figma",
        },
        {
            link: "/tutorialtwelve",
            img: Twelve,
            title: "Unique NextJS Navigation Bar with Framer Motion",
            details:
                "Learn how to create an impressive navbar with NextJS and Framer Motion",
            colorOne: "blue",
            skillOne: "nextJS",
            colorTwo: "red",
            skillTwo: "framer motion",
        },
        {
            link: "/tutorialeleven",
            img: Eleven,
            title: "NextJS and Figma – Designing & Coding a Card Component",
            details:
                "Learn how to create a stylish card component with Figma and NextJS",
            colorOne: "blue",
            skillOne: "nextJS",
            colorTwo: "green",
            skillTwo: "figma",
        },
        {
            link: "/tutorialten",
            img: Ten,
            title: "Video Background Component Using Next.js",
            details:
                "Learn how to import videos and create video backgrounds in NextJS",
            colorOne: "blue",
            skillOne: "nextJS",
            colorTwo: "red",
            skillTwo: "SASS",
        },
        {
            link: "/tutorialnine",
            img: Nine,
            title: "Accordion Menu Component with React JS",
            details: "Learn to create a unique accordion by using ReactJS",
            colorOne: "blue",
            skillOne: "react",
            colorTwo: "red",
            skillTwo: "CSS",
        },
        {
            link: "/tutorialeight",
            img: Eight,
            title: "AI Image Generator App in Javascript with OpenAI",
            details: "Learn to create your first AI app using OpenAI's APIs",
            colorThree: "yellow",
            skillThree: "Javascript",
            colorFour: "green",
            skillFour: "OpenAI",
        },
        {
            link: "/tutorialseven",
            img: Seven,
            title: "Responsive Landing Page Using HTML & CSS & JavaScript",
            details: "Learn to create a responsive landing page.",
            colorOne: "red",
            skillOne: "HTML",
            colorTwo: "blue",
            skillTwo: "CSS",
            colorThree: "yellow",
            skillThree: "Javascript",
        },
        {
            link: "/tutorialsix",
            img: Six,
            title: "How to Create a Glass Debit Card - using Spline 3D",
            details:
                "Learn to create a distinctive glass effect using Spline 3D.",
            colorOne: "yellow",
            skillOne: "spline 3D",
        },
        {
            link: "/tutorialfive",
            img: Five,
            title: "Responsive Landing Page - using React JS and Spline3D",
            details:
                "Create a standout landing page with React JS and Spline 3D using this tutorial.",
            colorOne: "blue",
            skillOne: "react",
            colorTwo: "yellow",
            skillTwo: "spline 3D",
            colorThree: "red",
            skillThree: "SASS/CSS",
        },
        {
            link: "/tutorialfour",
            img: Four,
            title: "Create Eye-Catching Button Hover Effects using Figma",
            details:
                "Creating Button Hover Animations with Figma: A Step-by-Step Guide",
            colorOne: "yellow",
            skillOne: "figma",
        },
        {
            link: "/tutorialthree",
            img: Three,
            title: "How to create a React JS sidebar navigation menu",
            details:
                "Creating a Responsive Sidebar with React and SASS/CSS: A Step-by-Step Tutorial",
            colorOne: "blue",
            skillOne: "react",
            colorTwo: "red",
            skillTwo: "SASS/CSS",
        },
        {
            link: "/tutorialtwo",
            img: Two,
            title: "Creating Website Mockups with AI: A Step-by-Step Guide",
            details:
                "Discover how AI can boost the efficiency of web designers and streamline their workflow with this informative guide.",
            colorOne: "blue",
            skillOne: "photoshop",
            colorTwo: "red",
            skillTwo: "figma",
        },
        {
            link: "/tutorialone",
            img: One,
            title: "Animated contact page using HTML, CSS & Javascript",
            details:
                "Learn how to create a unique, styled contact form for beginner frontend developers",
            colorOne: "red",
            skillOne: "html",
            colorTwo: "blue",
            skillTwo: "css",
            colorThree: "yellow",
            skillThree: "javascript",
        },
        {
            link: "/tutorialthirteen",
            img: Thirteen,
            title: "Architecture Section - Photoshop",
            details: "Learn to create a beautiful architecture section.",
            colorOne: "blue",
            skillOne: "photoshop",
        },
    ];

    const totalPages = Math.ceil(allCards.length / cardsPerPage);

    const nextPage = () => {
        if (page < totalPages - 1) {
            setPage(page + 1);
        } else {
            return;
        }
    };

    const previousPage = () => {
        if (page > 0) {
            setPage(page - 1);
        } else {
            return;
        }
    };

    const handlePage = () => {
        const startIndex = page * cardsPerPage;
        const endIndex = startIndex + cardsPerPage;

        return (
            <div className="cardsContainer">
                {allCards
                    .slice(startIndex, endIndex)
                    .map((card, index, array) => (
                        <Card
                            key={index}
                            {...card}
                            className={
                                index === array.length - 1 ? "lastCard" : ""
                            }
                        />
                    ))}
            </div>
        );
    };

    return (
        <section ref={secondRef} className={secondRefVisible ? "active" : ""}>
            {/* <div className="adContainer">
                <span className="advertisement">advertisement</span>
                <AdOne />
            </div> */}
            <div className="buttons">
                <button
                    className={page === 0 ? "previous active" : "previous"}
                    onClick={previousPage}
                >
                    previous
                </button>
                <button
                    className={page === totalPages - 1 ? "next active" : "next"}
                    onClick={nextPage}
                >
                    next
                </button>
            </div>
            {handlePage()}
            <div
                className="adContainer"
                style={{ padding: "4rem 0 4rem 0", margin: "4rem 0 8rem 0" }}
            >
                <span className="advertisement">advertisement</span>
                <AdTwo />
            </div>
        </section>
    );
};

export default Section;
