import React from "react";
import BlogContent from "../components/BlogContent";
import Img from "../images/tutorials/twentyone.png";

const TutorialTwentyOne = () => {
    return (
        <div>
            <BlogContent
                title="Create a Typography Hover Interaction with React and Framer Motion"
                date="Written by Thomas, Published on 16/05/2024"
                image={Img}
                alt="tutorial"
                video="https://youtu.be/KWDY4noZyFE"
                details="In this step-by-step tutorial, I will demonstrate how to create a typography interaction hover effect using React and Framer Motion. If you're interested in following along, you can watch the video and access the GitHub repository below."
                conclusion="I'm documenting my coding & design journey, from learning programming concepts to building and designing projects, on my YouTube channel. To follow along and stay updated, be sure to subscribe to my channel."
                github="https://github.com/emersonvisuals/typography-interaction-react-framermotion"
                githubButton="https://github.com/emersonvisuals/typography-interaction-react-framermotion"
                other="/"
                otherTitle=""
                otherButton="none"
            />
        </div>
    );
};

export default TutorialTwentyOne;
