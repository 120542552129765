import React from "react";
import BlogContent from "../components/BlogContent";
import Img from "../images/tutorials/six.png";

const TutorialSix = () => {
    return (
        <div>
            <BlogContent
                title="How to Create a Glass Debit Card - using Spline 3D"
                date="Written by Thomas, Published on the 20/03/2023"
                image={Img}
                alt="tutorial"
                video="https://youtu.be/zfmiHmnYty8"
                details="In this tutorial, you'll learn how to create a realistic glass debit card effect using Spline 3D. By following the step-by-step guide, you'll be able to create a unique and eye-catching glass debit card effect that you can use for your own projects. Whether you're a web developer or just someone interested in learning more about 3D design, this tutorial is a great way to expand your skill set and add a touch of sophistication to your work. So, let's get started and see what we can create with Spline 3D!"
                conclusion="I'm documenting my coding journey, from learning programming concepts to building and designing projects, on my YouTube channel. To follow along and stay updated, be sure to subscribe to my channel."
                github="https://www.figma.com/file/NwsXKhAgel3ioLmMmNItPB/Tutorial---06-(Copy)?node-id=0%3A1&t=IrA6h73yhF7eZKUN-1"
                otherButton="none"
            />
        </div>
    );
};

export default TutorialSix;
