import React from "react";
import BlogContent from "../components/BlogContent";
import Img from "../images/tutorials/eleven.png";

const TutorialEleven = () => {
    return (
        <div>
            <BlogContent
                title="NextJS and Figma Tutorial – Designing & Coding a Card Component"
                date="Written by Thomas, Published on 28/11/2023"
                image={Img}
                alt="tutorial"
                video="https://youtu.be/uYrtKrn3Gy0"
                details="
                Embark on a thrilling exploration of design and code with my latest tutorial video – 'NextJS and Figma Tutorial: Designing & Coding a Card Component.' Join me on a journey where we seamlessly transform Figma designs into a fully functional NextJS card component. From setting up your NextJS project to implementing interactive features and ensuring responsive design, this tutorial provides a comprehensive guide to elevate your skills in frontend development. Immerse yourself in the design-to-code process, and unlock the potential of creating stylish and responsive card components. Check out the video for a step-by-step walkthrough and enhance your proficiency in the exciting intersection of design and coding!"
                conclusion="I'm documenting my coding & design journey, from learning programming concepts to building and designing projects, on my YouTube channel. To follow along and stay updated, be sure to subscribe to my channel."
                github="https://github.com/emersonvisuals/tutorial-card-component"
                otherButton="none"
            />
        </div>
    );
};

export default TutorialEleven;
