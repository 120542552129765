import React from "react";
import BlogContent from "../components/BlogContent";
import Img from "../images/tutorials/five.png";

const TutorialFive = () => {
    return (
        <div>
            <BlogContent
                title="Generate a Responsive Landing Page - using React JS and Spline 3D"
                date="Written by Thomas, Published on the 06/03/2023"
                image={Img}
                alt="tutorial"
                video="https://youtu.be/U9L-0hPrWwo"
                details="Are you looking to create a stunning, responsive landing page using React JS and Spline 3D? Look no further! In this tutorial, I will guide you through the process of creating a landing page that stands out from the rest. By using Spline 3D, we will generate unique animations that are sure to catch your visitor's eye.
                To get started, you will need to access the styling sheets, font family, and images needed for this tutorial. Don't worry, these can be found in the assets button below. Once you have these assets, you can begin following along with the tutorial and creating your very own, unique landing page using React JS and Spline 3D."
                conclusion="I'm documenting my coding journey, from learning programming concepts to building and designing projects, on my YouTube channel. To follow along and stay updated, be sure to subscribe to my channel."
                github="https://github.com/emersonvisuals/tutorial-landing-page"
                otherButton="none"
            />
        </div>
    );
};

export default TutorialFive;
