import React, { useEffect } from "react";

const AdBannerTwo = () => {
    useEffect(() => {
        // Load Google AdSense script
        const script = document.createElement("script");
        script.async = true;
        script.src =
            "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3489691907740711";
        script.crossOrigin = "anonymous";
        document.head.appendChild(script);

        // Initialize AdSense
        script.onload = () => {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        };

        return () => {
            // Cleanup: Remove the script when the component unmounts
            document.head.removeChild(script);
        };
    }, []);

    return (
        <div>
            <ins
                className="adsbygoogle"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                data-ad-client="ca-pub-3489691907740711"
                data-ad-slot="2094898454"
                data-ad-format="auto"
                data-full-width-responsive="true"
            ></ins>
        </div>
    );
};

export default AdBannerTwo;
