import React from "react";
import BlogContent from "../components/BlogContent";
import Img from "../images/tutorials/eight.png";

const TutorialEight = () => {
    return (
        <div>
            <BlogContent
                title="Building an AI Image Generator App in Javascript with OpenAI's DALL-E API: A Step-by-Step Tutorial"
                date="Written by Thomas, Published on 25/06/2023"
                image={Img}
                alt="tutorial"
                video="https://youtu.be/_kUpyGQgIKA"
                details="
                Learn how to build a simple AI Image Generator App in JavaScript using OpenAI's DALL-E API. This tutorial will guide you through the process, from setting up the project to implementing the AI image generation functionality. By the end, you'll have an app that can generate unique and imaginative images with just a few lines of code. No prior knowledge of JavaScript, HTML, and CSS is required, and you'll need an OpenAI account to obtain an API key. Let's get started and unleash the power of AI in image generation!"
                conclusion="I'm documenting my coding & design journey, from learning programming concepts to building and designing projects, on my YouTube channel. To follow along and stay updated, be sure to subscribe to my channel."
                github="https://github.com/emersonvisuals/AI-image-generator-tutorial-openAI"
                otherButton="none"
            />
        </div>
    );
};

export default TutorialEight;
