import React from "react";
import "../styles/main.scss";
import DesktopLogo from "../images/desktopLogo.svg";
import MobileLogo from "../images/mobileLogo.svg";
import { useState } from "react";

const Navbar = () => {
    const [activateNav, setActivateNav] = useState(false);
    const [activateNavTwo, setActivateNavTwo] = useState(false);

    const handleActivateNav = () => {
        setActivateNav(!activateNav);
    };

    const handleActivateNavTwo = () => {
        setActivateNavTwo(!activateNavTwo);
    };
    return (
        <main>
            <nav
                className={
                    activateNavTwo === false
                        ? "mobileToggle"
                        : "mobileToggle active"
                }
            >
                <div className="desktopLogo">
                    <img src={DesktopLogo} alt="logo" />
                </div>
                <div className="mobileLogo">
                    <img src={MobileLogo} alt="logo" />
                </div>
                <div className="navList">
                    <ul>
                        <li>
                            <a
                                className="navHome"
                                href="https://www.emersonvisuals.com/"
                            >
                                home
                            </a>
                        </li>
                        <li
                            className="navPortfolio"
                            onClick={() => handleActivateNav()}
                        >
                            portfolio
                            <ul
                                className={
                                    activateNav === false
                                        ? "portfolioToggle"
                                        : "portfolioToggle active"
                                }
                            >
                                <li>
                                    <a
                                        className="portfolioToggleFont"
                                        id="portfolioToggleFontUXUI"
                                        href="https://www.emersonvisuals.com/uxuidesign/"
                                    >
                                        UX/UI Design
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="portfolioToggleFont"
                                        id="portfolioToggleFontGraphicDesign"
                                        href="https://www.emersonvisuals.com/graphicdesign/"
                                    >
                                        Graphic Design
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="portfolioToggleFont"
                                        id="portfolioToggleFontArchitecture"
                                        href="https://www.emersonvisuals.com/architecture/"
                                    >
                                        Architecture
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="portfolioToggleFont"
                                        id="portfolioToggleFontDigitalArtwork"
                                        href="https://www.emersonvisuals.com/digitalartwork/"
                                    >
                                        Digital Artwork
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="portfolioToggleFont"
                                        id="portfolioToggleFontFrontendDevelopment"
                                        href="https://www.emersonvisuals.com/frontenddevelopment/"
                                    >
                                        Frontend Development
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a
                                class="navResume"
                                href="https://tutorials.emersonvisuals.com"
                            >
                                tutorials
                            </a>
                        </li>
                        <li>
                            <a
                                class="navAbout"
                                href="https://www.emersonvisuals.com/about/"
                            >
                                about
                            </a>
                        </li>
                    </ul>
                    <div className="contactButtonContainer">
                        <a href="mailto:hello@emersonvisuals.com">
                            <button class="contactButton">contact</button>
                        </a>
                    </div>
                </div>
                <div className="mobileTrigger">
                    <div
                        className="trigger"
                        onClick={() => handleActivateNavTwo()}
                    ></div>
                    <div
                        className={
                            activateNavTwo === false
                                ? "mobileToggle"
                                : "mobileToggle active"
                        }
                    ></div>
                </div>
            </nav>
        </main>
    );
};

export default Navbar;
