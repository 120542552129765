import React from "react";
import BlogContent from "../components/BlogContent";
import ImgOne from "../images/tutorials/one.png";

const TutorialOne = () => {
    return (
        <div>
            <BlogContent
                title="How to make a contact page using HTML, CSS & Javascript"
                date="Written by Thomas,  Published on the 09/01/2023"
                image={ImgOne}
                alt="contact page thumbnail"
                video="https://www.youtube.com/watch?v=lks3b3Cy8Kw"
                details="In this step-by-step tutorial, learn how to create a professional contact page using HTML, CSS, and Javascript in the popular code editor, VScode. Follow along as I guide you through the process of building a functional contact page using these essential programming languages."
                conclusion="I'm documenting my coding journey, from learning programming concepts to building and designing projects, on my YouTube channel. To follow along and stay updated, be sure to subscribe to my channel."
                github="https://github.com/emersonvisuals/contact-page-tutorial"
                otherButton="none"
            />
        </div>
    );
};

export default TutorialOne;
