import React from "react";
import BlogContent from "../components/BlogContent";
import Img from "../images/tutorials/twelve.png";

const TutorialTwelve = () => {
    return (
        <div>
            <BlogContent
                title="Unique NextJS Navigation Bar with Framer Motion"
                date="Written by Thomas, Published on 11/12/2023"
                image={Img}
                alt="tutorial"
                video="https://youtu.be/TQcXSK0k52s"
                details="
                Embark on an exciting journey with me as we elevate navigation designs to new heights on my blog website. In this tutorial, I'll guide you through the dynamic fusion of NextJS and Framer Motion, showcasing the seamless integration of these powerful tools. Together, we'll delve into the intricacies of crafting a distinctive navigation menu from the ground up, ensuring a comprehensive and engaging learning experience. Join me in unlocking the potential of NextJS and Framer Motion for unparalleled navigation design! 🚀"
                conclusion="I'm documenting my coding & design journey, from learning programming concepts to building and designing projects, on my YouTube channel. To follow along and stay updated, be sure to subscribe to my channel."
                github="https://github.com/emersonvisuals/nextjs-framermotion-navbar"
                otherButton="none"
            />
        </div>
    );
};

export default TutorialTwelve;
