import React from "react";
import BlogContent from "../components/BlogContent";
import Img from "../images/tutorials/eighteen.png";

const TutorialSixteen = () => {
    return (
        <div>
            <BlogContent
                title="Recreating STRIPE's Hero Section With Next.js & Spline 3D"
                date="Written by Thomas, Published on 20/03/2024"
                image={Img}
                alt="tutorial"
                video="https://youtu.be/XV4_GNSXNIQ"
                details="In this step-by-step guide, I'll walk you through the process of recreating STRIPE's hero section using Next.js and Spline 3D. I will walk you through the steps of how to do this, go into STRIPE 3D to create the background animation and finally use Next.js to bring the project to life."
                conclusion="I'm documenting my coding & design journey, from learning programming concepts to building and designing projects, on my YouTube channel. To follow along and stay updated, be sure to subscribe to my channel."
                github="https://github.com/emersonvisuals/stripe-hero-recreation"
                githubButton="https://github.com/emersonvisuals/stripe-hero-recreation"
                other="/"
                otherTitle=""
                otherButton="none"
            />
        </div>
    );
};

export default TutorialSixteen;
