import React from "react";
import BlogContent from "../components/BlogContent";
import Img from "../images/tutorials/thirteen.jpg";

const TutorialTwelve = () => {
    return (
        <div>
            <BlogContent
                title="Architecture Section - Photoshop Tutorial"
                date="Written by Thomas, Published on 11/12/2023"
                image={Img}
                alt="tutorial"
                video="https://www.youtube.com/watch?v=eZISJcZuozU"
                details="
                In my YouTube video, I take you on a captivating journey into the world of architecture through the lens of Photoshop. Dive deep with me as I unveil the intricate process of creating a dedicated architecture section that seamlessly blends creativity and precision. From conceptualizing the design to refining the smallest details, I guide you step by step through the digital realm, showcasing the powerful tools and techniques that Photoshop offers. Whether you're an aspiring architect, a design enthusiast, or simply curious about the art of digital creation, this video is your passport to witnessing the magic unfold as we construct a visually stunning architecture section together. Join me on this visual adventure, where pixels and passion converge to bring architectural dreams to life!Embark on an exciting journey with me as we elevate navigation designs to new heights on my blog website. In this tutorial, I'll guide you through the dynamic fusion of NextJS and Framer Motion, showcasing the seamless integration of these powerful tools. Together, we'll delve into the intricacies of crafting a distinctive navigation menu from the ground up, ensuring a comprehensive and engaging learning experience. Join me in unlocking the potential of NextJS and Framer Motion for unparalleled navigation design! 🚀"
                conclusion="I'm documenting my coding & design journey, from learning programming concepts to building and designing projects, on my YouTube channel. To follow along and stay updated, be sure to subscribe to my channel."
                github="https://drive.google.com/drive/folders/16bIytZww3fgY5Cn76szj25LDYb5J-i49?usp=drive_link"
                other="https://emersonvisuals.gumroad.com/l/architecturebrushpack"
                otherTitle="brush pack!"
                otherButton="inital"
            />
        </div>
    );
};

export default TutorialTwelve;
