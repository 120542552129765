import React from "react";
import BlogContent from "../components/BlogContent";
import Img from "../images/tutorials/nineteen.png";

const TutorialSixteen = () => {
    return (
        <div>
            <BlogContent
                title="Create a Modern Content Carousel with Next.js and Framer Motion"
                date="Written by Thomas, Published on 09/04/2024"
                image={Img}
                alt="tutorial"
                video="https://youtu.be/TwcDgZBsFhY"
                details="In this step-by-step tutorial, I will demonstrate how to create a modern and unique content carousel from scratch using Next.js and Framer Motion. Additionally, we will utilise SCSS for styling throughout the process. If you're interested in following along, you can watch the video and access the GitHub repository below."
                conclusion="I'm documenting my coding & design journey, from learning programming concepts to building and designing projects, on my YouTube channel. To follow along and stay updated, be sure to subscribe to my channel."
                github="https://github.com/emersonvisuals/modern-carousel-design-tutorial"
                githubButton="https://github.com/emersonvisuals/modern-carousel-design-tutorial"
                other="/"
                otherTitle=""
                otherButton="none"
            />
        </div>
    );
};

export default TutorialSixteen;
