import React from "react";
import BlogContent from "../components/BlogContent";
import Img from "../images/tutorials/four.png";

const TutorialFour = () => {
    return (
        <div>
            <BlogContent
                title="How to Create Eye-Catching Button Hover Effects using Figma"
                date="Written by Thomas,  Published on the 19/02/2023"
                image={Img}
                alt="tutorial"
                video="https://youtu.be/eV7nq0K-fLc"
                details="If you're looking to create engaging hover animations for your buttons, you've come to the right place. In this tutorial, I'll guide you through the process of creating beginner-friendly hover animations for buttons using three different designs. And the best part? We'll be using Figma to bring our designs to life. Whether you're a seasoned designer or just starting, this tutorial will help you create stunning button animations that capture your users' attention. So let's dive in and get started!"
                conclusion="I'm documenting my coding journey, from learning programming concepts to building and designing projects, on my YouTube channel. To follow along and stay updated, be sure to subscribe to my channel."
                github="https://www.figma.com/file/RPuWFlwOslInntJsECEzNL/Tutorial-03---Hover-button-tutorial?node-id=0%3A1&t=q5KfPEyXPdcx7okU-1"
                otherButton="none"
            />
        </div>
    );
};

export default TutorialFour;
