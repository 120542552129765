import React from "react";
import BlogContent from "../components/BlogContent";
import Img from "../images/tutorials/three.png";

const TutorialTwo = () => {
    return (
        <div>
            <BlogContent
                title="How to create a React JS sidebar navigation menu"
                date="Written by Thomas,  Published on the 24/01/2023"
                image={Img}
                alt="tutorial"
                video="https://youtu.be/IQWrdGZvv6Y"
                details="If you're looking to create a beautiful and responsive website using React, you've come to the right place. In this tutorial, I'll walk you through the process step-by-step. We'll use different hooks to create the sidebar functionality, and SASS to style the site. With these tools, you'll be able to build a website that looks great on any device. Whether you're a seasoned developer or just starting, this tutorial will guide you through the process of creating a website that's both visually stunning and highly functional. So let's dive in and get started!"
                conclusion="I'm documenting my coding journey, from learning programming concepts to building and designing projects, on my YouTube channel. To follow along and stay updated, be sure to subscribe to my channel."
                github="https://github.com/emersonvisuals/tutorial-sidebar"
                otherButton="none"
            />
        </div>
    );
};

export default TutorialTwo;
