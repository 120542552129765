import React from "react";
import "../styles/main.scss";
import Arrow from "../images/arrow-new.svg";

const BreadCrumbs = () => {
    return (
        <div className="breadcrumbs">
            <a href="/">
                <img className="arrow" src={Arrow} alt="arrow" />
                <p>View more tutorials</p>
            </a>
        </div>
    );
};

export default BreadCrumbs;
