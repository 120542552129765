import React from "react";
import BlogContent from "../components/BlogContent";
import Img from "../images/tutorials/ten.png";

const TutorialNine = () => {
    return (
        <div>
            <BlogContent
                title="Building a Unique Accordion Menu Component with React JS: Step-by-Step Tutorial"
                date="Written by Thomas, Published on 19/11/2023"
                image={Img}
                alt="tutorial"
                video="https://youtu.be/JcYMICpVTCA"
                details="
                Uncover the intricacies of constructing a captivating background video component in NextJS! Follow along with this comprehensive, step-by-step tutorial, where I'll expertly guide you through creating a distinctive and interactive background video component from the ground up.
                "
                conclusion="I'm documenting my coding & design journey, from learning programming concepts to building and designing projects, on my YouTube channel. To follow along and stay updated, be sure to subscribe to my channel."
                github="https://github.com/emersonvisuals/nextjs-background-video"
                otherButton="none"
            />
        </div>
    );
};

export default TutorialNine;
