import React from "react";
import BlogContent from "../components/BlogContent";
import Img from "../images/tutorials/fourteen.png";

const TutorialFourteen = () => {
    return (
        <div>
            <BlogContent
                title="6 AI Tools with Figma - Rapid Web Design Tutorial"
                date="Written by Thomas, Published on 06/01/2024"
                image={Img}
                alt="tutorial"
                video="https://youtu.be/jXaPGlBZ5VU"
                details="
                Embark on an exhilarating design odyssey with me as six groundbreaking AI tools revolutionise the digital landscape of a surfboard company's website. Witness the magic unfold, starting with Relume's ingenious AI wireframes, seamlessly transitioning into Figma's unparalleled customisation capabilities. The vibrant palette from AI Colour breathes life into the digital canvas, while ChatGPT adds a personal touch that resonates with authenticity. Midjourney's images and the refining prowess of Vectorizer and Photoshop AI elevate the design to new heights. The result is nothing short of a spectacular, harmoniously integrated design masterpiece. If you crave more of this AI-driven adventure, hit the subscribe button for a journey through the realms of Fullstack design. 🚀"
                conclusion="I'm documenting my coding & design journey, from learning programming concepts to building and designing projects, on my YouTube channel. To follow along and stay updated, be sure to subscribe to my channel."
                github="https://www.figma.com/file/S3JUmZWxgvr6zyx4GkKTL2/Surfboard-Design?type=design&node-id=0%3A1&mode=design&t=xi9BSiEte3oK22B0-1"
                other="/"
                otherTitle=""
                otherButton="none"
            />
        </div>
    );
};

export default TutorialFourteen;
