import React from "react";
import BlogContent from "../components/BlogContent";
import ImgTwo from "../images/tutorials/two.png";

const TutorialTwo = () => {
    return (
        <div>
            <BlogContent
                title="How to Use AI to create a Website Mockup"
                date="Written by Thomas,  Published on the 24/01/2023"
                image={ImgTwo}
                alt="contact page thumbnail"
                video="https://youtu.be/u1VqF0t9UBU"
                details="This tutorial showcases how web designers can use AI to generate mockups for their clients. The tutorial covers the use of various programs, including Midjourney for creating images, ChatGPT for crafting titles, and Figma or Photoshop for assembling the design."
                conclusion="I'm documenting my coding journey, from learning programming concepts to building and designing projects, on my YouTube channel. To follow along and stay updated, be sure to subscribe to my channel."
                github="https://github.com/emersonvisuals/ai-web-design-tutorial"
                otherButton="none"
            />
        </div>
    );
};

export default TutorialTwo;
